import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as _find from 'lodash/find';
import { reduxForm, initialize, reset, getFormSyncErrors } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { connect, batch } from 'react-redux';
import {
  LinearProgress,
  Paper,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
//actions
import * as actions from 'actions/timecards';
import {
  submitAllAsRejected,
  reviewBatch,
  setSelectedFlows,
} from 'actions/reviews';
import {
  fetchStatesV1,
  fetchCitiesV1,
  storeStatesV1,
  storeCitiesV1,
} from 'actions/location';
import { fetchPayrollSettings } from 'actions/settings';
import { fetchPermissions } from 'actions/userInfo';
import { show as showModal } from 'actions/modalDialog';
import { fetchAllowancePayCode, fetchCurrentApprovers } from 'actions/wtc';
// selectors
import { currentUser, userDeleted } from 'selectors/session';
import * as timecardCommon from 'selectors/timecard/common';
import {
  getTimecard,
  getInitialValues,
  getTimecardAllowances,
  getAllowanceInput,
} from 'selectors/timecard/form';
import { getProject } from 'selectors/routeParams';
import { getProjectDetails } from 'selectors/project';
import { getStateOptions, getCityOptions } from 'selectors/wtc';
import { getPermissions } from 'selectors/userInfo';
import { getSettings, getProjectPayrollSettings } from 'selectors/settings';
import { getCurrentApprovers } from 'selectors/wtc';
import EmergencyTimecard from '../CrewTimecard/Modals/EmergencyTimecard';
//utils
import moment from 'utils/moment';

// components
import { userInfoProps } from 'components/props/profiles';
import Timecard from 'components/Employees/Timecards/Timecard';
import { projectProps } from 'components/props/projects';
import { timecardProps } from 'components/props/timecard';
import ConfirmTakeMeBack from 'containers/Employees/Timecards/Modals/ConfirmTakeMeBack';
import DeleteTimecardAllowance from 'containers/Employees/Timecards/Modals/DeleteTimecardAllowance';
import { REJECTION_FLOW_STANDARD } from 'components/Shared/constants';
import RejectApprovalFlow from './Reviewer/RejectApprovalFlow';
import DeleteTimecard from '../Timecards/Modals/DeleteTimecard';
import SubmitDraftModal from 'containers/Employees/Reviews/SearchTimecards/SubmitDraftModal';

//utils
import { DH, UPM } from 'components/props/profiles';
import { timecardValidate as validate } from 'utils/weekUtils';
const formName = 'timecard';

const onSubmit = (values, dispatch) => {
  dispatch(actions.setCurrentTimecard({ timecardId: values.id }));
  dispatch(actions.onSave({ timecard: values }));
};

const mapState = (state, { match, location }) => {
  const path = location.pathname || '';
  const isViewOnly = path.indexOf('/review') >= 0;
  const timecardId =
    timecardCommon.getCurrentTimecard(state) || match.params.timecardId;

  return {
    activeUser: currentUser(state),
    currentApprovers: getCurrentApprovers(state),
    comments: timecardCommon.getComment(state),
    isUserDeleted: userDeleted(state),
    allowances: getTimecardAllowances(state, timecardId, false),
    allowanceInput: getAllowanceInput(state),
    permissions: getPermissions(state),
    dayTypes: timecardCommon.getDayTypes(state),
    enableESignature: timecardCommon.getESignatureStatus(state),
    episodes: timecardCommon.getEpisodes(state),
    fromHistory: true,
    fromURI: timecardCommon.getFromURI(state),
    initialValues: getInitialValues(state, timecardId), //initialized in componentDidUpdate
    isAllowanceProcessing: timecardCommon.isAllowanceProcessing(state),
    isNotesProcessing: timecardCommon.isNotesProcessing(state),
    isViewOnly: isViewOnly,
    loading: timecardCommon.getLoadingDetails(state),
    payrollSettings: getProjectPayrollSettings(state),
    project: getProjectDetails(state),
    projectId: getProject(state),
    projectSettings: getSettings(state),
    savingTimecard: timecardCommon.isSavingTimecard(state),
    cityOptions: getCityOptions(state),
    stateOptions: getStateOptions(state),
    timecard: getTimecard(state, timecardId, formName),
    timecardAllowances: timecardCommon.parsedAllowancePayCodes(state),
    timecardId,
    workLocations: timecardCommon.getWorkLocations(state),
    formSyncErrors: getFormSyncErrors(formName)(state),
  };
};

const mapDispatch = (dispatch, ownProps) => {
  const timecardId = ownProps.match.params.timecardId;
  return {
    onFetchData: () => {
      batch(() => {
        dispatch(actions.initTimecard());
        dispatch(actions.loadingDetails({ loading: true }));
        dispatch(fetchPayrollSettings());
        dispatch(fetchAllowancePayCode());
        dispatch(fetchPermissions());
        dispatch(
          actions.fetchTimecardDetails({
            timecardId,
          }),
        );
        dispatch(fetchCurrentApprovers({ timecardId }));
      });
    },
    onSaveNote: (note, type, timecardId, date) => {
      const value = { note, type, timecardId, date };
      dispatch(actions.saveTimeCardNotes({ value }));
    },
    onShowDirtyAlert: () =>
      dispatch(showModal({ dialog: 'TakeMeBack', maxWidth: 'md' })),

    onTakeMeBackAction: () => dispatch(actions.onTakeMeBack()),
    onSubmitAsRejected: () =>
      dispatch(submitAllAsRejected({ reviewType: 'pending', timecardId })),
    onDownloadDocument: (file, token) => {
      dispatch(actions.downloadSupportingDocument({ token, file }));
    },

    onShowSubmitModal: timecard => {
      const timecards = [timecard];
      dispatch(
        showModal({
          dialog: 'SubmitDraftModal',
          modalParams: { timecards, variant: 'reviewTimecard' },
        }),
      );
    },
    onSubmitEmergencyTimecard: () => {
      dispatch(showModal({ dialog: 'emergencyTimecard' }));
    },
    onSaveAllowance: (index, allowance, timecardId) => {
      dispatch(actions.saveTimecardAllowance({ index, allowance, timecardId }));
    },
    onSelectAllowanceType: ({ index, timecardId, allowanceType }) => {
      dispatch(
        actions.selectAllowanceType({ index, timecardId, allowanceType }),
      );
    },
    onSelectAllowanceRate: ({ index, timecardId, rate }) => {
      dispatch(actions.selectAllowanceRate({ index, timecardId, rate }));
    },
    onSelectAllowanceUnits: ({ index, timecardId, units }) => {
      dispatch(actions.selectAllowanceUnits({ index, timecardId, units }));
    },
    onSelectAllowanceAmount: ({ index, timecardId, amount }) => {
      dispatch(actions.selectAllowanceAmount({ index, timecardId, amount }));
    },
    onSelectAllowanceCombineCheckCode: ({
      index,
      timecardId,
      combineCheckCode,
    }) => {
      dispatch(
        actions.selectAllowanceCombineCheckCode({
          index,
          timecardId,
          combineCheckCode,
        }),
      );
    },
    onUploadDocument: ({ index, timecardId, document }) => {
      dispatch(
        actions.uploadAllowanceDocument({ index, timecardId, document }),
      );
    },
    onResetAllowance: ({ index, timecardId }) => {
      dispatch(
        actions.resetAllowanceInput({
          index,
          timecardId,
        }),
      );
    },
    onDeleteTimecardAllowance: () => {
      dispatch(showModal({ dialog: 'DeleteTimecardAllowance' }));
    },
    onApproveTimecard: timecardEntryHeaderId => {
      dispatch(
        reviewBatch({
          comment: '',
          status: 'approved',
          formName,
          timecardEntryHeaderIds: [timecardEntryHeaderId],
        }),
      );
    },
    onRejectTimecard: approvalFlowId => {
      dispatch(setSelectedFlows({ selectedApprovalFlows: approvalFlowId }));
      dispatch(showModal({ dialog: 'RejectApprovalFlow', maxWidth: 'md' }));
    },
    onClearCurrentTC: () => {
      dispatch(actions.clearCurrentTimecard());
    },
    onDeleteTimecard: () => {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(showModal({ dialog: 'DeleteTimecard', maxWidth: 'md' }));
    },
    onFetchStates: args => dispatch(fetchStatesV1(args)),
    onFetchCities: args => dispatch(fetchCitiesV1(args)),

    onResetLoc: variant => {
      switch (variant) {
        case 'workState':
          dispatch(storeStatesV1({ states: [] }));
          break;
        case 'workCity':
          dispatch(storeCitiesV1({ cities: [] }));
          break;
        default:
          break;
      }
    },
  };
};

class ReviewTimecard extends Component {
  static propTypes = {
    activeUser: userInfoProps.isRequired,
    editableFields: PropTypes.object,
    location: PropTypes.object.isRequired,
    project: projectProps.isRequired,
    projectId: PropTypes.string.isRequired,
    timecard: timecardProps.isRequired,
    timecardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = {
      allowanceId: null,
      allowanceInProgress: false,
      areNotesEmpty: true,
      notesEmpty: {},
      editDraft: false,
    };
    this.setNoteEmpty = this.setNoteEmpty.bind(this);
    this.setAllowanceInProgress = this.setAllowanceInProgress.bind(this);
    this.onEditTimecard.bind(this);
  }
  componentDidMount() {
    this.props.onFetchData();
  }

  componentWillUnmount() {
    this.props.onClearCurrentTC();
  }

  // The getInitialValues selector re-runs too often because it uses a currying func as a dependency.
  // This was causing the redux-form to reinitialize at every pass
  // by watching until loading is finished, then initializing with the static values we avoid that issue.
  componentDidUpdate(prevProps) {
    if (
      prevProps.loading !== this.props.loading &&
      this.props.loading === false
    ) {
      this.props.dispatch(initialize(formName, this.props.initialValues));
    }
  }

  setNoteEmpty(noteId, isEmpty) {
    const notesEmpty = this.state.notesEmpty;
    notesEmpty[noteId] = isEmpty;

    let areNotesEmpty = true;
    for (const note in notesEmpty) {
      if (notesEmpty.hasOwnProperty(note)) {
        const setNoteEmpty = notesEmpty[note];
        areNotesEmpty = areNotesEmpty && setNoteEmpty;
      }
    }
    this.setState({ areNotesEmpty, notesEmpty });
  }

  setAllowanceInProgress(val) {
    this.setState({ allowanceInProgress: val });
  }

  onDeleteAllowance(id) {
    this.setState({ allowanceId: id });
    setTimeout(() => this.props.onDeleteTimecardAllowance(), 100);
  }

  onTakeMeBack() {
    const { pristine } = this.props;
    if (!pristine) {
      this.props.onShowDirtyAlert();
    } else {
      this.props.onTakeMeBackAction();
    }
  }

  onEditTimecard() {
    this.setState({
      editDraft: !this.state.editDraft,
    });
  }

  onCopyToAll(referenceDate) {
    const { timecard } = this.props;
    const days = timecard.days;
    const referenceDay = _.find(days, day =>
      moment(day.date).isSame(referenceDate),
    );
    _.map(days, (day, index) => {
      if (!day.isActive) return day;

      const newDay = _.cloneDeepWith(referenceDay, () => {
        return {
          ...referenceDay,
          date: day.date,
          id: day.id,
          splits: null,
          isActive: true,
        };
      });

      this.props.change(`days[${index}]`, newDay);
      return {
        ...newDay,
      };
    });
  }

  actionButtons() {
    const {
      timecard,
      activeUser,
      isUserDeleted,
      onSubmitAsRejected,
      isViewOnly,
      isTeamTimecard = false,
      submitting,
      currentApprovers,
      permissions,
      dispatch,
      isAllowanceProcessing,
    } = this.props;
    const editDraft = this.state.editDraft;
    /**
     * @submitting
     * a built-in flag from redux-form
     * turns on and off by
     * startSubmit/ stopSubmit actions calls
     */
    const disabled =
      submitting ||
      !this.state.areNotesEmpty ||
      this.state.allowanceInProgress ||
      isUserDeleted ||
      isAllowanceProcessing;

    const canSubmitEmergencyTimecard =
      permissions &&
      permissions.departments &&
      permissions.departments[timecard.departmentId] &&
      permissions.departments[timecard.departmentId].canSubmitEmergencyTimecard;
    const buttons = [];

    const { valid } = validate(timecard, {
      pristine: false,
      isReviewing: true,
    });

    const missingTimeMsg = valid
      ? ''
      : 'Missing requires times. Edit the timecard and add the missing times';

    if (activeUser.role === DH) {
      if (
        timecard.status === 'incomplete' &&
        timecard.rejectionWorkflow === REJECTION_FLOW_STANDARD &&
        isTeamTimecard
      ) {
        return [
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitAsRejected}
            disabled={disabled}
            key={'submitReject'}
          >
            Submit As Rejected
          </Button>,
        ];
      } else if (timecard.status === 'draft') {
        const parsedTC = {
          timecardId: timecard.id,
          timecardEntryHeaderId: timecard.entryHeaderId,
          status: 'Draft',
          employee: timecard?.user?.fullName,
          isValid: valid,
        };
        if (!editDraft) {
          return [
            <Button
              variant="outlined"
              color="primary"
              key="edit"
              onClick={() => this.onEditTimecard()}
              disabled={disabled}
            >
              {'Edit'}
            </Button>,
            <Button
              variant="contained"
              color="primary"
              key="submitemployee"
              disabled={disabled}
              onClick={() => this.props.onShowSubmitModal(parsedTC)}
            >
              {'Submit to Employee'}
            </Button>,
            <Tooltip key="submit" arrow title={missingTimeMsg}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  key="submitemergency"
                  disabled={disabled || !canSubmitEmergencyTimecard || !valid}
                  onClick={this.props.onSubmitEmergencyTimecard}
                >
                  {'Submit Emergency Timecard'}
                </Button>
              </span>
            </Tooltip>,
          ];
        } else {
          return [
            <Button
              variant="outlined"
              key="save"
              onClick={() => {
                this.props.handleSubmit();
                this.onEditTimecard();
              }}
              disabled={disabled || !valid}
            >
              {'Save'}
            </Button>,
            <Button
              variant="outlined"
              key="cancel"
              onClick={() => {
                this.onEditTimecard();
                dispatch(reset(formName));
              }}
              disabled={disabled}
            >
              {'Cancel'}
            </Button>,
            <Button
              variant="contained"
              color="primary"
              key="submitemployee"
              disabled={disabled || !valid}
              onClick={() => this.props.onShowSubmitModal(parsedTC)}
            >
              {'Submit to Employee'}
            </Button>,
            <Tooltip key="submit" arrow title={missingTimeMsg}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  key="submitemergency"
                  disabled={disabled || !canSubmitEmergencyTimecard || !valid}
                  onClick={this.props.onSubmitEmergencyTimecard}
                >
                  {'Submit Emergency Timecard'}
                </Button>
              </span>
            </Tooltip>,
          ];
        }
      }
    }

    if (
      isViewOnly &&
      timecard.status === 'pending_upm_review' &&
      activeUser.role === UPM
    ) {
      //For UPM, see if activeUser is in the approvers list
      const activeUserId = activeUser && activeUser.id;

      const currUserIsApprover = _find(
        currentApprovers,
        app => app.approverId === activeUserId,
      );

      if (!!currUserIsApprover) {
        return [
          <Button
            variant="outlined"
            color="primary"
            key="reject"
            disabled={disabled}
            onClick={() =>
              this.props.onRejectTimecard(timecard.activeApprovalFlowId)
            }
          >
            {'Reject'}
          </Button>,
          <Button
            variant="contained"
            color="primary"
            key="submit"
            disabled={disabled}
            onClick={() => this.props.onApproveTimecard(timecard.entryHeaderId)}
          >
            {'Approve'}
          </Button>,
        ];
      }
    }

    return buttons;
  }

  render() {
    const { timecard, handleSubmit, isViewOnly, isUserDeleted, comments } =
      this.props;

    if (this.props.loading) {
      return <LinearProgress />;
    }
    if (!timecard.id) {
      return (
        <Paper style={{ padding: 25, textAlign: 'center' }}>
          <Typography variant="h6">{'Timecard Not Found'}</Typography>
        </Paper>
      );
    }
    return (
      <div>
        {(this.props.submitting ||
          this.props.savingTimecard ||
          this.props.isAllowanceProcessing) && <LinearProgress />}
        <form onSubmit={handleSubmit}>
          <Timecard
            {...this.props}
            isViewOnly={
              !this.state.editDraft &&
              (isViewOnly || this.props.enableESignature || isUserDeleted)
            }
            onTakeMeBack={this.onTakeMeBack.bind(this)}
            editDraft={this.state.editDraft}
            onCopyToAll={this.onCopyToAll.bind(this)}
            buttons={this.actionButtons()}
            onDeleteAllowance={this.onDeleteAllowance.bind(this)}
            setNoteEmpty={this.setNoteEmpty}
            setAllowanceInProgress={this.setAllowanceInProgress}
            isReviewing
            allowanceEditable
          />
        </form>
        <SubmitDraftModal />
        <ConfirmTakeMeBack
          hasSubmitAction
          onSubmit={this.props.onTakeMeBackAction}
        />
        <RejectApprovalFlow />
        <DeleteTimecardAllowance
          worksightId={this.state.allowanceId}
          timecardId={this.props.timecardId}
        />
        <EmergencyTimecard
          id={this.props.timecardId}
          weekEnding={this.props.timecard.weekEnding}
          departmentId={this.props.timecard.departmentId}
          isTeamTimecard={false}
        />
        <DeleteTimecard comments={comments} />
      </div>
    );
  }
}

export default compose(
  connect(mapState, mapDispatch),

  reduxForm({
    form: formName,
    enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(ReviewTimecard);
