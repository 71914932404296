import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reset, reduxForm, getFormSyncErrors } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import {
  Button,
  LinearProgress,
  Paper,
  Typography,
  Tooltip,
} from '@mui/material';
//actions
import * as actions from 'actions/timecards';

import {
  fetchStatesV1,
  fetchCitiesV1,
  storeStatesV1,
  storeCitiesV1,
} from 'actions/location';
import { fetchPayrollSettings } from 'actions/settings';
import { show as showModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';
import { fetch as fetchProjectAllowances } from 'actions/projectAllowances';
import { reviewApprovalFlow, EditTimecardApprovalFlow } from 'actions/reviews';

// selectors
import { currentUser, userDeleted } from 'selectors/session';
import * as timecardCommon from 'selectors/timecard/common';
import {
  getTimecard,
  getInitialValues,
  getTimecardAllowances,
  getDropdownAllowances,
  getCopyPreviousFlag,
  getAllowanceInput,
} from 'selectors/timecard/form';
import { getProject } from 'selectors/routeParams';
import { getProjectDetails, getRegion } from 'selectors/project';
import { getStateOptions, getCityOptions } from 'selectors/wtc';
import { getSettings, getProjectPayrollSettings } from 'selectors/settings';
import { getCopyFromPrevRoundFlag } from 'selectors/timecards';
// utils
import moment from 'utils/moment';
import { timecardValidate as validate } from 'utils/weekUtils';

// components
import { userInfoProps } from 'components/props/profiles';
import InactiveUser from 'containers/Employees/InactiveUser';
import Timecard from 'components/Employees/Timecards/Timecard';
import { projectProps } from 'components/props/projects';
import { timecardProps } from 'components/props/timecard';
import DeleteTimecard from './Modals/DeleteTimecard';
import ConfirmSubmit from './Modals/ConfirmSubmit';
import ConfirmTakeMeBack from './Modals/ConfirmTakeMeBack';
import ConfirmCopyPrevious from './Modals/ConfirmCopyPrevious';
import DeleteTimecardAllowance from './Modals/DeleteTimecardAllowance';
import RejectTimecard from '../Reviews/Reviewer/RejectTimecard';
import EditTimecard from '../Reviews/Reviewer/EditTimecard';

const formName = 'timecard';

const mapState = (state, { location, match }) => {
  const fromURL = location.pathname || '';
  const isViewOnly = fromURL.indexOf('/review') >= 0;
  const searchString = location.search ? location.search : '';
  const fromHistory = searchString.indexOf('fromHistory=true') >= 0;
  const fromFinish = searchString.indexOf('finishTimecard=true') >= 0;
  const timecardId = match.params.timecardId;
  let isReadyForReview = fromURL.indexOf('approval-flows') >= 0;
  const isTimecardViewOnly = searchString.indexOf('finishTimecard=false') >= 0;

  return {
    activeUser: currentUser(state),
    isUserDeleted: userDeleted(state),
    allowances: getTimecardAllowances(state, timecardId, true),
    allowanceInput: getAllowanceInput(state),
    comments: timecardCommon.getComment(state),
    dayTypes: timecardCommon.getDayTypes(state),
    enableESignature: timecardCommon.getESignatureStatus(state),
    episodes: timecardCommon.getEpisodes(state),
    fromFinish,
    fromHistory,
    isTimecardViewOnly: isTimecardViewOnly,
    fromURI: timecardCommon.getFromURI(state),
    initialValues: getInitialValues(state, timecardId, 'employee'),
    isAllowanceProcessing: timecardCommon.isAllowanceProcessing(state),
    isNotesProcessing: timecardCommon.isNotesProcessing(state),
    isViewOnly: isViewOnly,
    isReadyForReview,
    loading: timecardCommon.getLoadingDetails(state),
    payrollSettings: getProjectPayrollSettings(state),
    project: getProjectDetails(state),
    projectId: getProject(state),
    projectSettings: getSettings(state),
    region: getRegion(state),
    savingTimecard: timecardCommon.isSavingTimecard(state),
    cityOptions: getCityOptions(state),
    stateOptions: getStateOptions(state),
    timecard: getTimecard(state, timecardId, formName, 'employee'),
    timecardAllowances: getDropdownAllowances(state),
    timecardId,
    workLocations: timecardCommon.getWorkLocations(state),
    isCopyPrevious: getCopyPreviousFlag(state),
    formSyncErrors: getFormSyncErrors(formName)(state),
    localNotes: timecardCommon.getLocalNotes(state),
    copyFromPrevRoundFlag: getCopyFromPrevRoundFlag(state),
  };
};

const mapDispatch = (dispatch, ownProps) => {
  const timecardId = ownProps.match.params.timecardId;

  return {
    onFetchData: timecardId => {
      dispatch(actions.initTimecard());
      dispatch(fetchPayrollSettings()); // setting
      dispatch(fetchProjectAllowances()); // projectAllowance
      dispatch(actions.fetchTimecardDetails({ timecardId }));
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(actions.loadingInitialize({ loading: false }));
    },
    onSaveNote: (note, type, timecardId, referenceDate) => {
      const value = { note, type, timecardId, referenceDate };
      dispatch(actions.saveTimeCardNotes({ value }));
    },
    onShowDirtyAlert: () =>
      dispatch(showModal({ dialog: 'TakeMeBack', maxWidth: 'md' })),

    onTakeMeBackAction: () => {
      dispatch(actions.setFlagCopyPrevious({ flag: false }));
      dispatch(actions.onTakeMeBack());
    },
    onDeleteTimecard: () => {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(showModal({ dialog: 'DeleteTimecard', maxWidth: 'md' }));
    },
    onSubmitTimecard: (showCopyToAllModal, isCopyPrevious, pristine) => {
      if (showCopyToAllModal) {
        dispatch(showModal({ dialog: 'ConfirmSubmit', maxWidth: 'md' }));
      } else if (isCopyPrevious && pristine) {
        dispatch(showModal({ dialog: 'ConfirmCopyPrevious', maxWidth: 'md' }));
      } else {
        dispatch(actions.setCurrentTimecard({ timecardId }));
        dispatch(actions.onSubmit());
      }
    },

    onSaveTimecard: () => {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(actions.onSave());
    },
    onApproveTimecard: (
      esignature,
      agreed,
      isReadyForReview = false,
      isTimecardViewOnly,
      comments,
      timecardEntryHeaderId,
    ) => {
      //Crew timecard Approval
      if (isReadyForReview && comments.status !== 'rejected') {
        dispatch(actions.setCurrentTimecard({ timecardId }));
        dispatch(
          reviewApprovalFlow({
            status: 'approved',
            comment: '',
            agreeToTerms: true,
            electronicSignature: esignature,
            formName,
            employeeApprove: true,
            timecardEntryHeaderId,
          }),
        );
      } else {
        if (
          isTimecardViewOnly &&
          comments &&
          comments.status &&
          comments.status === 'rejected'
        ) {
          dispatch(actions.setCurrentTimecard({ timecardId }));
          dispatch(
            EditTimecardApprovalFlow({
              status: 'rejected',
              formName: 'RejectTimecard',
              comment: comments.comments,
              type: 'reviews/EditTimecardapprovalFlow',
              esignature: esignature,
              agreed: agreed,
              timecardEntryHeaderId,
            }),
          );
        } else {
          //Regular timecard approval
          dispatch(actions.setCurrentTimecard({ timecardId }));
          dispatch(actions.onApprove({ esignature, agreed }));
        }
      }
    },
    onPrintTimecard: () => {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(actions.printTimecard());
    },
    onDownloadDocument: (file, token) => {
      dispatch(actions.downloadSupportingDocument({ token, file }));
    },
    onSaveAllowance: (index, allowance, timecardId) => {
      dispatch(actions.saveTimecardAllowance({ index, allowance, timecardId }));
    },
    onSelectAllowanceType: ({ index, timecardId, allowanceType }) => {
      dispatch(
        actions.selectAllowanceType({ index, timecardId, allowanceType }),
      );
    },
    onSelectAllowanceAmount: ({ index, timecardId, amount }) => {
      dispatch(actions.selectAllowanceAmount({ index, timecardId, amount }));
    },
    onSelectAllowanceCombineCheckCode: ({
      index,
      timecardId,
      combineCheckCode,
    }) => {
      dispatch(
        actions.selectAllowanceCombineCheckCode({
          index,
          timecardId,
          combineCheckCode,
        }),
      );
    },
    onUploadDocument: ({ index, timecardId, document }) => {
      dispatch(
        actions.uploadAllowanceDocument({ index, timecardId, document }),
      );
    },
    onResetAllowance: ({ index, timecardId }) => {
      dispatch(
        actions.resetAllowanceInput({
          index,
          timecardId,
        }),
      );
    },
    onDeleteTimecardAllowance: () => {
      dispatch(showModal({ dialog: 'DeleteTimecardAllowance' }));
    },
    onRejectTimecard: () => {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(showModal({ dialog: 'RejectTimecard', maxWidth: 'md' }));
    },
    onApprovalClick: () => {
      dispatch(actions.enableESignature({ enableESignature: true }));
    },
    onHideSignature: () => {
      dispatch(actions.enableESignature({ enableESignature: false }));
    },
    clearLocalData: () => {
      dispatch(actions.clearLocalTCNotes());
      dispatch(actions.clearLocalTimecardAllowance());
    },
    loadDayTypes: inputText => {
      if (inputText && inputText.trim()) {
        dispatch(actions.fetchDayTypes({ search: inputText }));
      } else {
        dispatch(actions.fetchDayTypes());
      }
    },
    onClearCurrentTC: () => {
      dispatch(actions.clearCurrentTimecard());
    },
    onEditTimecard() {
      dispatch(actions.setCurrentTimecard({ timecardId }));
      dispatch(showModal({ dialog: 'EditTimecard', maxWidth: 'md' }));
    },
    onClearComment: () => {
      dispatch(actions.clearEditComment());
      dispatch(reset(formName));
    },
    onResetValidation: ({ change }) =>
      dispatch(actions.resetValidation({ timecardId, change })),

    onFetchStates: args => dispatch(fetchStatesV1(args)),
    onFetchCities: args => dispatch(fetchCitiesV1(args)),
    onResetLoc: variant => {
      switch (variant) {
        case 'workState':
          dispatch(storeStatesV1({ states: [] }));
          break;
        case 'workCity':
          dispatch(storeCitiesV1({ cities: [] }));
          break;
        default:
          break;
      }
    },
    onSetCopyFromPrevRoundFlag: () =>
      dispatch(
        actions.setCopyFromPrevRoundFlag({ copyFromPrevRoundFlag: false }),
      ),
    onShowAlert: args => dispatch(showAlert(args)),
  };
};

class MyTimecard extends Component {
  static propTypes = {
    activeUser: userInfoProps.isRequired,
    editableFields: PropTypes.object,
    location: PropTypes.object.isRequired,
    project: projectProps.isRequired,
    projectSettings: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    timecard: timecardProps.isRequired,
    timecardId: PropTypes.string.isRequired,
    isCopyPrevious: PropTypes.bool.isRequired,
    comments: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      editReview: false,
      allowanceId: null,
      allowanceInProgress: false,
      areNotesEmpty: true,
      notesEmpty: {},
    };
    this.setNoteEmpty = this.setNoteEmpty.bind(this);
    this.setAllowanceInProgress = this.setAllowanceInProgress.bind(this);
    this.submitTimeCard = this.submitTimeCard.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  //This should be allowance worksightId, not H+ id
  onDeleteAllowance(id, index) {
    if (typeof id === 'undefined') {
      this.setState({ allowanceId: index });
    } else {
      this.setState({ allowanceId: id });
    }

    setTimeout(() => this.props.onDeleteTimecardAllowance(), 100);
  }

  componentDidMount() {
    const {
      match: {
        params: { timecardId },
      },
    } = this.props;
    this.props.onFetchData(timecardId);
  }
  componentWillUnmount() {
    this.props.onClearCurrentTC();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editReview && prevState.editReview === false) {
      const days = this.props.initialValues.days;
      const change = this.props.change;
      this.props.onResetValidation({ days, change });
    }

    if (
      this.props.copyFromPrevRoundFlag &&
      this.props.loading === false &&
      !!this.props.timecard
    ) {
      setTimeout(() => {
        this.props.onShowAlert({
          message: (
            <div>
              Source timecard and new occupation code have different rounding
              values.
              <br /> Values have been rounded accordingly.
            </div>
          ),
          variant: 'info',
          action: () => {}, //setting non-null to have autoHide be 5 sec instead of 2
        });
        this.props.onSetCopyFromPrevRoundFlag();
      }, 2000);
    }

    //Need to get validation to run on the initial load
    //but don't want to turn off the pristine check in the validation function
    if (
      this.props.pristine &&
      !this.props.isViewOnly &&
      this.props.initialValues?.days?.length === 7
    ) {
      const errors = validate(this.props.initialValues, { pristine: false });
      if (errors.valid === false) {
        this.props.change('makeFormNotPristine', true);
      }
    }
  }

  submitTimeCard() {
    const { onSubmitTimecard, pristine, isCopyPrevious, comments } = this.props;
    const matched = this.checkCopyToAllMatch();
    this.props.change(`action`, 'approve');
    onSubmitTimecard(matched, isCopyPrevious, pristine, comments);
  }

  setNoteEmpty(noteId, isEmpty) {
    const notesEmpty = this.state.notesEmpty;
    notesEmpty[noteId] = isEmpty;

    let areNotesEmpty = true;
    for (const note in notesEmpty) {
      if (notesEmpty.hasOwnProperty(note)) {
        const setNoteEmpty = notesEmpty[note];
        areNotesEmpty = areNotesEmpty && setNoteEmpty;
      }
    }
    this.setState({ areNotesEmpty, notesEmpty });
  }

  setAllowanceInProgress(val) {
    this.setState({ allowanceInProgress: val });
  }

  beforeSave() {
    const matched = this.checkCopyToAllMatch();
    this.props.change(`action`, 'save');
    this.props.onSaveTimecard(matched);
  }

  checkCopyToAllMatch() {
    const { timecard } = this.props;
    const isCopyToAllDays = timecard.isCopyToAllDays;
    let matched = null;

    if (isCopyToAllDays) {
      const days = timecard.days;
      if (days.length > 1) {
        const filteredDays = _.filter(days, day => day.isActive);
        const firstDay = filteredDays[0];
        _.forEach(filteredDays, (day, index) => {
          if (index >= 1) {
            _.forEach(Object.keys(day), key => {
              if (
                (matched == null || matched) &&
                key !== 'id' &&
                key !== 'date' &&
                key !== 'rerate' &&
                key !== 'state' &&
                key !== 'city' &&
                key !== 'dayType' &&
                key !== 'isActive'
              ) {
                matched = firstDay[key] === day[key] ? true : false;
              }
            });
          }
        });
      }
    }
    return matched;
  }

  onCopyToAll(referenceDate) {
    const { timecard } = this.props;
    const days = timecard.days;
    const referenceDay = _.find(days, day =>
      moment(day.date).isSame(referenceDate),
    );

    _.map(days, (day, index) => {
      if (!day.isActive) return day;

      const newDay = _.cloneDeepWith(referenceDay, () => {
        return {
          ...referenceDay,
          date: day.date,
          id: day.id,
          splits: null,
          isActive: true,
        };
      });

      this.props.change(`days[${index}]`, newDay);
      this.props.change(`isCopyToAllDays`, true);

      return {
        ...newDay,
      };
    });
  }

  onTakeMeBack() {
    const { pristine, anyTouched } = this.props;
    if (!pristine && anyTouched) {
      this.props.onShowDirtyAlert();
    } else {
      this.props.onTakeMeBackAction();
    }
  }
  onCancel() {
    const { onClearComment } = this.props;
    onClearComment();
    this.setState({
      editReview: !this.state.editReview,
    });
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    // Need to reinitialize the redux-form on cancel, but since I cleared that for the edit, easier to just reload the page.
  }
  enable() {
    this.setState({
      editReview: !this.state.editReview,
    });
  }
  actionButtons() {
    const buttons = [];
    const editReview = this.state.editReview;
    const {
      submitting,
      isViewOnly,
      timecard,
      fromHistory,
      activeUser,
      isUserDeleted,
      fromFinish,
      invalid,
      isTimecardViewOnly,
      allowances,
      isAllowanceProcessing,
    } = this.props;

    const activeDays =
      (timecard.days && timecard.days.filter(day => day.isActive)) || [];
    const validRow = activeDays.filter(day => !!day.htgDayTypeId).length <= 0;
    const allowancesOnly = allowances.length > 0;

    const enabledForDeleted =
      submitting ||
      (validRow && !allowancesOnly) ||
      !this.state.areNotesEmpty ||
      this.state.allowanceInProgress;

    const disabled =
      enabledForDeleted || isUserDeleted || isAllowanceProcessing;

    //Employee Crew Timecard approval
    if (
      isViewOnly &&
      timecard.status === 'pending_employee_review' &&
      fromFinish &&
      timecard.user.id === activeUser.id
    ) {
      buttons.push(
        <Button
          variant="outlined"
          color="primary"
          key="reject"
          disabled={disabled}
          onClick={this.props.onRejectTimecard}
        >
          {'Reject'}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="submit"
          disabled={disabled}
          onClick={() => this.props.onApprovalClick()}
        >
          {'Approve'}
        </Button>,
      );
    }
    //Employee Crew Timecard approval
    if (
      timecard.status === 'pending_employee_review' &&
      isTimecardViewOnly &&
      timecard.user.id === activeUser.id
    ) {
      const { valid } = validate(timecard, {
        pristine: false,
        isReviewing: true,
      });

      const missingTimeMsg = valid
        ? ''
        : 'Missing requires times. Edit the timecard and add the missing times';

      if (!editReview) {
        buttons.push(
          <Button
            variant="outlined"
            color="primary"
            key="reject"
            disabled={disabled}
            onClick={() => {
              this.props.onEditTimecard();
            }}
          >
            {'Edit'}
          </Button>,
          <Tooltip key="submit" arrow title={missingTimeMsg}>
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={disabled || !!missingTimeMsg}
                onClick={() => this.props.onApprovalClick()}
              >
                {'Approve'}
              </Button>
            </span>
          </Tooltip>,
        );
      } else {
        buttons.push(
          <Button
            variant="text"
            color="primary"
            key="cancel"
            disabled={disabled}
            onClick={() => this.onCancel()}
          >
            {'Cancel'}
          </Button>,
          <Button
            variant="contained"
            color="primary"
            key="submit"
            disabled={disabled || invalid}
            onClick={() => this.submitTimeCard()}
          >
            {'Submit'}
          </Button>,
        );
      }
    }

    //Employee Created Approval
    if (
      !isViewOnly &&
      timecard.status === 'incomplete' &&
      !this.props.enableESignature
    ) {
      buttons.push(
        <Button
          variant="outlined"
          color="primary"
          key="save"
          disabled={disabled || invalid}
          onClick={this.beforeSave.bind(this)}
        >
          {'Save Work'}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="submit"
          disabled={disabled || invalid}
          onClick={this.submitTimeCard}
        >
          {'Submit'}
        </Button>,
      );
    } else if (fromHistory) {
      buttons.push(
        <Button
          variant="contained"
          color="primary"
          key="print"
          disabled={enabledForDeleted}
          onClick={this.props.onPrintTimecard}
        >
          {'Print'}
        </Button>,
      );
    }

    return buttons;
  }

  render() {
    const {
      activeUser,
      timecard,
      timecardId,
      handleSubmit,
      isViewOnly,
      enableESignature,
      isUserDeleted,
      isTimecardViewOnly,
      comments,
      stateOptions,
      cityOptions,
      project,
    } = this.props;

    let reviewtrue = isViewOnly || enableESignature || isUserDeleted;
    if (timecard.status === 'pending_employee_review') {
      reviewtrue = !this.state.editReview || enableESignature || isUserDeleted;
    }

    if (this.props.loading || !timecard) {
      return <LinearProgress />;
    }

    if (
      !activeUser ||
      !activeUser.hasWorksightAccount ||
      !activeUser.department
    ) {
      return <InactiveUser />;
    }

    if (!timecard.id) {
      return (
        <Paper style={{ padding: 25, textAlign: 'center' }}>
          <Typography variant="h6">{'Timecard Not Found'}</Typography>
        </Paper>
      );
    }

    return (
      <div>
        {(this.props.submitting || this.props.savingTimecard) && (
          <LinearProgress />
        )}
        <form onSubmit={handleSubmit}>
          <Timecard
            project={project}
            onCopyToAll={this.onCopyToAll.bind(this)}
            buttons={this.actionButtons()}
            //isViewOnly={!this.state.editReview || isUserDeleted}
            editReview={this.state.editReview}
            onTakeMeBack={this.onTakeMeBack.bind(this)}
            isNotesProcessing={this.props.isNotesProcessing}
            onDeleteAllowance={this.onDeleteAllowance.bind(this)}
            userRole="employee"
            allowanceEditable
            change={this.props.change}
            setNoteEmpty={this.setNoteEmpty}
            setAllowanceInProgress={this.setAllowanceInProgress}
            {...this.props}
            isViewOnly={reviewtrue}
            isTimecardViewOnly={isTimecardViewOnly}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
          />
        </form>
        <DeleteTimecard comments={comments} />
        <DeleteTimecardAllowance
          worksightId={this.state.allowanceId}
          timecardId={timecardId}
        />
        <RejectTimecard timecardEntryHeaderId={timecard.entryHeaderId} />
        <EditTimecard
          timecardEnable={this.setState.bind(this)}
          editReview={this.state.editReview}
          timecardEntryHeaderId={timecard.entryHeaderId}
        />
        <ConfirmTakeMeBack
          hasSubmitAction
          onSubmit={this.props.onTakeMeBackAction}
        />
        <ConfirmSubmit
          timecardId={timecardId}
          action={this.props.timecard.action}
        />
        <ConfirmCopyPrevious
          timecardId={timecardId}
          timecardEnd={timecard.endsOn}
        />
      </div>
    );
  }
}

export default compose(
  connect(mapState, mapDispatch),

  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
  }),
)(MyTimecard);
